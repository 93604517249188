.contact_section {
    position: relative;
}

.contact_section .heading_container {
    margin-bottom: 25px;
    padding-top: 25px;
}

.contact_section .heading_container h2 {
    text-transform: uppercase;
}

.contact_section .form_container {
    margin-right: 45px;
}

.contact_section .form_container input {
    width: 100%;
    border: 1px solid #19717D;
    height: 50px;
    margin-bottom: 25px;
    padding-left: 15px;
    background-color: transparent;
    outline: none;
    color: #101010;
}

.contact_section .form_container textarea{
    width: 100%;
    border: 1px solid #19717D;
    height: 50px;
    margin-bottom: 25px;
    padding-left: 15px;
    background-color: transparent;
    outline: none;
    color: #101010;
}



.contact_section .form_container input::-webkit-input-placeholder {
    color: #19717D;
}

.contact_section .form_container textarea::-webkit-input-placeholder {
    color: #19717D;
}

.contact_section .form_container textarea:-ms-input-placeholder {
    color: #19717D;
}

.contact_section .form_container input::-ms-input-placeholder {
    color: #19717D;
}

.contact_section .form_container input::placeholder {
    color: #19717D;
}

.contact_section .form_container textarea::placeholder {
    color: #19717D;
}

.contact_section .form_container textarea.message-box {
    height: 120px;
}



.contact_section .form_container .button {
    border: none;
    text-transform: uppercase;
    display: inline-block;
    padding: 15px 65px;
    background-color: #19717D;
    color: #ffffff;
    border-radius: 0;
    font-weight: bold;
}

.contact_section .form_container .button:hover {
    background-color: #00937d;
}

/* css for submit successfull message  */
.contact_section .form_container .btnn {
    margin-top: 70px;
}

.contact_section .form_container .message {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    text-align: center; /* Center the text */
}

.contact_section .form_container .message .success,
.contact_section .form_container .message .empty,
.contact_section .form_container .message .connection,
.contact_section .form_container .message .registered {
    font-size: 20px;
    position: relative;
    transition: .3s;
    animation: buttons .3s linear;
    padding: 10px;
    word-wrap: break-word; /* Ensure words wrap within the container */
    max-width: 100%; /* Ensure it doesn't exceed the container width */
}

.contact_section .form_container .message .success {
    color: #19717D;
}

.contact_section .form_container .message .empty {
    color: #791c17;
}

.contact_section .form_container .message .connection {
    color: #177917;
}

.contact_section .form_container .message .registered {
    color: brown;
}

@keyframes buttons {
    0% {
        transform: scale(0.1);
    }

    50% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(0.9);
    }
}

.contact_section .img-box img {
    width: 100%;
}

.hidden {
    display: none;
}

@media (max-width: 768px) {
    .contact_section .row {
        flex-direction: column;
        align-items: center;
    }

    .contact_section .form_container {
        margin-right: 0;
    }

    .contact_section .heading_container h2 {
        font-size: 24px;
    }

    .contact_section .form_container .button {
        padding: 15px 30px;
    }

    .contact_section .form_container .btnn {
        margin-top: 30px;
    }

    .contact_section .form_container .message div {
        font-size: 18px;
    }
}

@media (max-width: 576px) {
    .contact_section {
        padding: 10px;
    }

    .contact_section .heading_container h2 {
        font-size: 20px;
    }

    .contact_section .form_container input {
        height: 40px;
        margin-bottom: 15px;
    }

    .contact_section .form_container input.message-box {
        height: 80px;
    }

    .contact_section .form_container .button {
        padding: 10px 20px;
        /* margin-top: 20px; */
    }

    .contact_section .form_container .message .success,
    .contact_section .form_container .message .empty,
    .contact_section .form_container .message .connection,
    .contact_section .form_container .message .registered{
        font-size: 16px;
    }
}

@media (max-width: 220px) {

    .contact_section .form_container .button {

        margin-top: 35px;
    }
}


@media (max-width: 200px) {

    .contact_section .form_container .button {
        /* padding: 10px 20px; */
        margin-top: 20px;
    }

    .contact_section .form_container .message .success,
    .contact_section .form_container .message .empty,
    .contact_section .form_container .message .connection,
    .contact_section .form_container .message .registered {

        text-align: center;
    }

}

@media (max-width: 127px) {

    .contact_section .form_container .button {

        margin-top: 40px;
    }
}