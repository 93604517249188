.scroll-to-top-button {
  position: fixed;
  bottom: 180px;
  right: 20px;
  width: 90px;
  height: 90px;
  border-radius: 5px;
  background-color: transparent;  
  background-image: url('../assets/Ambulance.png'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  z-index: 1;
  animation: upDownAnimation 3s infinite;
}

 .scroll-to-top-button:hover,
.scroll-to-top-button:focus {
  background-color: transparent;  
  outline: none;  
}

.loader.hide {
  display: none;
}

@keyframes upDownAnimation {
  0%, 100% {
    transform: translateY(0); 
  }
  50% {
    transform: translateY(-10px);  
  }
}

@media (max-width: 768px) {
  .scroll-to-top-button {
    width: 70px;
    height: 70px;
    bottom: 120px;
  }
}

@media (max-width: 480px) {
  .scroll-to-top-button {
    width: 50px;
    height: 50px;
    bottom: 120px;
  }
}