.career_section {
  background: linear-gradient(
    180deg,
    rgb(0 95 115 / 54%) 0%,
    rgba(0, 47, 58, 1) 100%
  );
  opacity: 0.95;
  padding: 50px 0;
}

.career_section .heading_container {
  margin-bottom: 45px;
}

.career_section .heading_container h2 {
  color: #000000;
  text-transform: uppercase;
  margin: 0;
}

.career_section .heading_container h2 span {
  color: #ffffff;
}
.jobs-list-container {
    max-width: 900px;
    margin: 20px auto;
    font-family: "Rubik", sans-serif;
  }
  
  .jobs-list-container h2 {
    font-size: 30px;
    border-left: 4px solid #023047;
    padding-left: 20px;
  }
  
  .jobs-list-container .jobs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  }
  
  .jobs-list-container .jobs img {
    width: 60px;
  }
  
  .jobs-list-container .job {
    box-shadow: 0 4px 24px -8px rgba(2, 48, 71, 0.2);
    background-color: whitesmoke;
    padding: 24px;
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    transition: all 400ms ease;
  }
  
  .jobs-list-container .job:hover {
    transform: scale(1.03);
  }
  
  .jobs-list-container .open-positions {
    position: absolute;
    top: 40px;
    right: 24px;
    color: #e63946;
  }
  
  .jobs-list-container .job-title {
    font-size: 20px;
    font-weight: 900;
    padding: 10px 0;
    margin: 0;
    color: #023047;
  }
  
  .jobs-list-container .details {
    margin-top: 6px;
    font-size: 14px;
    font-weight: 800;
    color: #333;
    line-height: 1.8;
    flex: 1;
  }
  
  .jobs-list-container .details-btn {
    text-decoration: none;
    border: 1px solid #023047;
    color: #000;
    padding: 8px 16px;
    border-radius: 8px;
    text-align: center;
    margin-top: 20px;
    transition: all 400ms ease;
  }
  
  .jobs-list-container .details-btn:hover {
    background: #023047;
    color: #fff;
  }
  
  .jobs-list-container .job-search {
    width: 100%;
    padding: 12px 24px;
    font-size: 18px;
    font-family: "Rubik", sans-serif;
    box-sizing: border-box;
    border: 1px solid #333;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .jobs-list-container .btn_box {
    border: none;
    text-transform: uppercase;
    display: block;
    position: relative;
    margin: 20px auto;
    padding: 15px 65px;
    background-color: #02372f;
    color: #ffffff;
    border-radius: 0;
    font-weight: bold;
  }
  
  .jobs-list-container .btn_box:hover {
    background-color: #032d33;
  }
  
  @media (max-width: 940px) {
    .jobs-list-container .jobs {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media (max-width: 640px) {
    .jobs-list-container .jobs {
      grid-template-columns: 1fr;
      max-width: 400px;
      margin: 0 auto;
    }
  }
  
  /* job application form */