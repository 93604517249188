.hero_area {
  position: relative;
}

.sub_page .hero_area {
  height: auto;
}

.about_section {
  margin-top: 50px;
  /* Adjust this value as needed */
  margin-bottom: 30px;
}

.about_section .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about_section .img-box img {
  max-width: 100%;
  
}
.heading_container h2 {
  position: relative;
  font-weight: bold;
}
.heading_container h2 span {
  color: #19717d;
}
.about_section .detail-box p {
  color: #999;
  margin-top: 15px;
}

.about_section .detail-box button {
  display: inline-block;
  padding: 10px 45px;
  background-color: #252525;
  color: #ffffff;
  border-radius: 5px;
  margin-top: 15px;
  text-decoration: none;
}

.about_section .detail-box button:hover {
  background-color: #0c0c0c;
}

.video-box {
  background: none;
  /* border-top-left-radius: 30px;
  border-bottom-right-radius: 30px; */
  overflow: hidden;
  /* box-shadow: 0 6px 8px gray; */
  width: 90%;
  height:99%;
  background-color: #da0000;
}


@keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }

  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }

  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}