@media (max-width: 1326px) {
  .info_section .info_form form button {
    margin-left: -85px;
  }
}
@media (max-width: 1300px) {
  .feature_section .side_img {
    opacity: 0.2;
  }
}

@media (max-width: 1120px) {}

@media (max-width: 1100px) {
  .info_section .info_form form button {
    margin-left: -85px;
  }
}

@media (max-width: 1000px) {
  .info_section .info_form form button {
    margin-left: -80px;
  }
}

@media (max-width: 1120px) {}

@media (max-width: 1120px) {}

@media (max-width: 992px) {
  .hero_area {
    height: auto;
  }

  .header_section {
    margin-top: 0;
  }

  .header_section .header_top .contact_nav {
    justify-content: space-between;
  }

  .quote_btn-container {
    flex-direction: column;
    align-items: center;
  }

  .quote_btn-container a {
    margin-right: 0;
    margin-top: 10px;
  }

  .slider_section {
    padding: 75px 0 125px 0;
  }

  .feature_section .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .feature_section .img-box {
    display: flex;
    justify-content: center;
  }

  .feature_section .detail-box {
    margin-top: 15px;
  }

  .img-box img {
    max-width: 100%;
    height: auto;
  }

  .faq_section .box {
    margin: 15px;
  }

  .faq_section .carousel_btn-box {
    display: flex;
    justify-content: center;
    margin-top: 45px;
  }

  .faq_section .carousel-control-prev,
  .faq_section .carousel-control-next {
    position: unset;
    margin: 0 2.5px;
    width: 45px !important;
    height: 45px !important;
  }

  .info_section .info_main_row>div {
    margin-bottom: 25px;
  }
}

@media (max-width: 768px) {
  .about_section .detail-box {
    margin-top: 45px;
  }

  .slider_section .dot_design {
    opacity: 0.4;
  }

  .service_section .team_carousel {
    padding: 0;
  }

  .service_section .team_carousel .owl-nav {
    /* margin-top: 4px; */
    gap: 5px;
  }

  .owl-prev,
  .owl-next {
    visibility: hidden;
    position: unset !important;
    /* margin-top: -10px; */

    /* transform: none; */
    /* transform: translateX(1000 %) !important; */
  }

  .owl-prev {
    transform: translateX(550%) !important;
    transform: translateY(950%) !important;
    margin-left: 30vh;
    margin-bottom: -70px;
  }

  .owl-next {
    transform: translateX(500%) !important;
    margin-top: 52px;
  }

  .contact_section .form_container {
    margin-right: 0;
    margin-bottom: 25px;
  }

  .info_section .info_main_row>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .info_section .info_main_row>div:nth-last-child(1) {
    margin-bottom: 0;
  }

  .info_section .info_contact {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info_section .social_box {
    justify-content: center;
  }

  .info_section .info_post {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info_section .info_post .post_box {
    flex-direction: column;
    align-items: center;
  }

  .info_section .info_post .post_box .img-box {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .header_section .header_top .contact_nav a {
    margin-right: 0;
  }

  .header_section .header_top .contact_nav a span {
    display: none;
  }

  .header_section .header_top .contact_nav a i {
    font-size: 28px;
    margin: 0;
  }

  .feature_section .side_img {
    display: none;
  }

  .info_section .info_top {
    flex-direction: column;
  }

  .info_section .info_top .info_logo {
    margin-right: 0;
    margin-bottom: 25px;
  }

  .owl-prev,
  .owl-next {
    visibility: hidden;
    position: unset !important;
    /* margin-top: -10px; */

    /* transform: none; */
    /* transform: translateX(1000 %) !important; */
  }

  .service_section .container .slick-slider {
    top: 50px
  }

  .owl-prev {
    transform: translateX(550%) !important;
    transform: translateY(950%) !important;
    margin-left: 30vh;
    margin-bottom: -108px;
  }

  .owl-next {
    transform: translateX(500%) !important;
    margin-top: 52px;
  }

  .info_section .info_form form {
    flex-direction: column; /* Stack elements vertically */
    align-items: stretch;   /* Stretch elements to fill container */
  }

  .info_section .info_form form button {
    margin-top: 10px; /* Add margin to create space between input and button */
    width: 100%;      /* Make the button full width */
  }
}

@media (max-width: 480px) {}

@media (max-width: 420px) {}

@media (max-width: 376px) {
  .slider_section .detail_box h1 {
    font-size: 2.5rem;
  }
}

@media (max-width: 990px) {
  .service_section .contain {
    margin-top: -30px;
  }
}

@media (max-width: 991px) {
  .service_section .box .detail-box .twentyfour {
    margin-bottom: 32px;
  }

  @media (max-width: 769px) {
    .service_section .box .detail-box .twentyfour {
      margin-bottom: 9px;
    }

    .service_section .box .detail-box .pad3 {
      padding-bottom: 0px;
    }

    .service_section .box .detail-box .pad {
      padding-bottom: 0px;
    }

    .service_section .box .detail-box .pad2 {
      padding-bottom: 0px;
    }
  }
}

@media (max-width: 768px) {
  .service_section .contain {
    margin-top: 0px;
  }

  .service_section .box .detail-box .pad {
    padding-bottom: 35px;
  }

  .service_section .box .detail-box .pad2 {
    padding-bottom: 38px;
  }

  .service_section .box .detail-box .twentyfourA {
    margin-bottom: 1px;
  }

  .service_section .box .detail-box .pad4 {
    padding-bottom: 14px;
  }
}

@media (max-width: 575px) {
  .service_section .contain {
    margin-top: 0px;
  }

  .service_section .slick-slider .slick-initialized {
    top: 30px;
  }

  .service_section .box .detail-box .pad {
    padding-bottom: 0px;
  }

  .service_section .box .detail-box .pad2 {
    padding-bottom: 17px;
  }

  .service_section .box .detail-box .twentyfourA {
    margin-bottom: 4px;
  }

  .service_section .box .detail-box .pad4 {
    padding-bottom: 17px;
  }
}

@media (max-width: 340px) {
  .service_section .slick-slider .slick-initialized {
    top: 30px;
  }

  .service_section .box .detail-box .pad4 {
    padding-bottom: 0px;
    margin-bottom: -1px;
  }
}

@media (max-width: 321px) {
  .service_section .slick-slider .slick-initialized {
    top: 30px;
  }

  .service_section .box .detail-box h5 {
    margin-top: -15px;
  }

  .service_section .box .detail-box .pad4 {
    margin-top: -14px;
  }

  .service_section .box .detail-box .pad3 {
    margin-top: -9px;
    padding-bottom: 0px;
    padding-top: 2px;
  }
}

@media (max-width: 295px) {
  .service_section .box .detail-box h5 {
    margin-top: -0px;
  }

  .service_section .box .detail-box .pad4 {
    margin-top: 0px;
  }

  .service_section .box .detail-box .pad5 {
    padding-bottom: 13px;
  }

  .service_section .box .detail-box .pad2 {
    padding-bottom: 13px;
  }

  .service_section .box .detail-box .pad3 {
    margin-top: -0px;
    padding-bottom: 0px;
    padding-top: 2px;
  }
}

@media (max-width: 271px) {
  .service_section .box .detail-box h5 {
    margin-top: -0px;
  }

  .service_section .box .detail-box .pad4 {
    margin-top: 0px;
  }

  .service_section .box .detail-box .pad5 {
    padding-bottom: 20px;
  }

  .service_section .box .detail-box .pad2 {
    padding-bottom: 12px;
  }

  .service_section .box .detail-box .pad6 {
    padding-bottom: 26px;
  }
}

@media (max-width: 175px) {
  .contact_section .form_container .btnn {
    margin-top: 100px;
  }
}

@media (max-width: 155px) {
  .contact_section .form_container .btnn {
    margin-top: 120px;
  }
}

@media (max-width: 1200px) {
  .service_section .detail-box .pad {
    padding-bottom: 20px;
  }

  .service_section .detail-box .pad2 {
    padding-bottom: 23px;
  }

  .service_section .detail-box .pad3 {
    padding-bottom: 5px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}