.white_page {
    fill: #fff;
}

.dark_gray_page {
    fill: #a9a9a9;
}

.medium_gray {
    fill: #ccc;
}

.light_orange {
    fill: #fca671;
}

.orange {
    fill: #e96019;
}

.top-martini {
    fill: #fdd2b8;
}

.dark_navy {
    fill: #1b2c3d;
}

.green {
    fill: #a48801;
}

.med-blue {
    fill: #395d6d;
}

.light-blue {
    fill: #e8eaec;
}

.extra-light-o {
    fill: #fce7dc;
}

.extra2-light-o {
    fill: #fff2ea;
}

.navy-light {
    fill: #495664;
}

.light-green {
    fill: #bfac4d;
}

.light-bubbles {
    fill: #e1e7e9;
}

.CNFcontainer {
    margin: auto;
    width: 100%;
    text-align: center;
    position: relative;
    height: 100vh;
    background-color: #395d6d;
}

/* Base styles for all screen sizes */

.CNFtext {
    width: 30%;
    text-align: center;
    z-index: 1000;
    position: absolute;
    background-color: white;
    padding: 3rem;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    top: 30%;
    box-shadow: 7px 7px 10px #27414c;
    display: flex;  
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.CNFh1 {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-size: 2rem;
    text-align: center;
    margin: auto;
    color: #395d6d;
    letter-spacing: 0.1rem;
    padding-bottom: 0.5rem;
}

.CNFh2 {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    margin: auto;
    color: #395d6d;
    letter-spacing: 0.1rem;
    padding-bottom: 2rem;
}

.CNFh3 {
    font-family: "Lato", sans-serif;
    font-weight: 900;
    font-size: 0.8rem;
    text-align: center;
    margin: auto;
    letter-spacing: 0.1rem;
}

.CNFlink {
    color: #19717D;  
    text-decoration: none;
}

.CNFlink:hover {
    color: #e1e7e9;
}

.CNFbutton {
    width: 6rem;
    border: 3px solid #19717D;
    margin: auto;
    text-align: center;
    padding: 0.5rem;
    color: #19717D;
}

#home:hover {
    background-color: #19717D;
    color: white;
}






.CNFtext {
    position: absolute;
    background-color: white;
    padding: 3rem;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    top: 30%;
    box-shadow: 7px 7px 10px #27414c;
    display: flex;  
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 30%; /* Adjust this percentage to your needs */
    height: auto; /* Let height be auto to maintain aspect ratio */
    padding-top: 8%; /* Increase to make the container taller */
    box-sizing: border-box; /* Include padding in width and height calculations */
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .CNFtext {
        width: 40%;  
        padding-top: 55%; /* Adjust for new width */
        padding: 2rem;  
    }
}

@media (max-width: 992px) {
    .CNFtext {
        width: 50%;  
        padding-top: 65%; /* Adjust for new width */
        padding: 2.5rem;  
    }
}

@media (max-width: 768px) {
    .CNFtext {
        width: 60%;  
        padding-top: 75%; /* Adjust for new width */
        padding: 1rem;  
    }
}

@media (max-width: 576px) {
    .CNFtext {
        width: 80%;  
        padding-top: 85%; /* Adjust for new width */
        padding: 0.5rem;  
    }
}
.CNFsvg {
    text-align: center;
    position: absolute;
    width: 90%;
   left: 0;
   right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}

#hotel-top {
    animation: fall-8 8s ease-in infinite;
    opacity: 0.5;
}

#left-martini-page {
    animation: fall-8 8s ease-in infinite;
    opacity: 0.5;
}

#top-spa {
    animation: fall-7 7s ease-in infinite;
    opacity: 0.75;
}

#chemistry-big {
    animation: fall-12 12s ease-in infinite;
}

#comp-big {
    animation: fall-13 13s ease-in infinite;
    opacity: 0.5;
}

#martini-bottom {
    animation: fall-12 12s ease-in infinite;
}

#spa-bottom {
    animation: fall-10 10s ease-in infinite;
}

#hotel-bottom {
    animation: fall-10 10s ease-in infinite;
    opacity: 0.25;
}

#chemistry-little {
    animation: fall-8 8s ease-in infinite;
}

#hotel-tiny {
    animation: fall-10 10s ease-in infinite;
}

#chemistry-top-right {
    animation: fall-10 10s ease-in infinite;
}

#comp-little {
    animation: fall-7 7s ease-in infinite;
}

.page-fall2 {
    animation: fall-12 12s ease-in infinite;
}

.page-fall3 {
    animation: fall-13 13s ease-in infinite;
    opacity: 0.5;
}

@keyframes fall-13 {
    from {
        transform: translateY(-700px);
    }

    to {
        transform: translateY(800px);
    }
}

@keyframes fall-7 {
    from {
        transform: translateY(-700px);
    }

    to {
        transform: translateY(800px);
    }
}

@keyframes fall-8 {
    from {
        transform: translateY(-700px);
    }

    to {
        transform: translateY(800px);
    }
}

@keyframes fall-10 {
    from {
        transform: translateY(-700px);
    }

    to {
        transform: translateY(800px);
    }
}

@keyframes fall-12 {
    from {
        transform: translateY(-700px);
    }

    to {
        transform: translateY(800px);
    }
}