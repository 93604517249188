/* slider section */
.hero_area {
  position: relative;
}

.sub_page .hero_area {
  height: auto;
}

.slider_section {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  padding-top: 45px;
  padding-bottom: 75px;
}

.slider_section .dot_design {
  position: absolute;
  width: 450px;
  right: 0;
  bottom: 0;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  z-index: 1;
}

.slider_section .dot_design img {
  width: 100%;
}

.slider_section .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider_section #customCarousel1 {
  width: 100%;
  position: unset;
}

.slider_section .play_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 65px;
  height: 65px;
  position: relative;
  z-index: 3;
  margin-top: 15px;
  margin-bottom: 45px;
}

.slider_section .play_btn button {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  border: none;
  position: relative;
  z-index: 5;
  outline: none;
  animation: blink-animation 1s infinite;
}

.slider_section .play_btn .siren_image {
  width: 100px; /* Adjust the width as needed */
  height: auto;
  z-index: 5;
}

@keyframes blink-animation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.slider_section .detail-box {
  color: #252525;
}

.slider_section .detail-box h1 {
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.slider_section .detail-box h1 span {
  color: #19717d;
}
.slider_section .detail-box .Emergency {
  color: #e71b1b;
}
.slider_section .detail-box h2 span {
  color: #19717d;
}

.slider_section .detail-box p {
  color: #6d6d6d;
}

.slider_section .detail-box a {
  display: inline-block;
  padding: 10px 45px;
  background-color: #252525;
  color: #ffffff;
  border-radius: 5px;
  margin-top: 5px;
  text-decoration: none;
}

.slider_section .detail-box a:hover {
  background-color: #0c0c0c;
}

.slider_section .img-box img {
  width: 100%;
}

.slider_section .carousel_btn-box {
  position: absolute;
  bottom: 0;
  right: 10%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 110px;
  height: 50px;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  z-index: 3;
}

.slider_section .carousel_btn-box .carousel-control-prev,
.slider_section .carousel_btn-box .carousel-control-next {
  position: unset;
  width: 50px;
  height: 50px;
  background-color: #19717d;
  opacity: 1;
}

.slider_section .carousel_btn-box .carousel-control-prev img,
.slider_section .carousel_btn-box .carousel-control-next img {
  width: 10px;
}

.slider_section .carousel_btn-box .carousel-control-prev:hover,
.slider_section .carousel_btn-box .carousel-control-next:hover {
  background-color: #252525;
}
