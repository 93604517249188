.service_section {
  background: linear-gradient(
    180deg,
    rgb(0 95 115 / 54%) 0%,
    rgba(0, 47, 58, 1) 100%
  );
  opacity: 0.95;
  padding: 50px 0;
}

.service_section .heading_container {
  margin-bottom: 45px;
}

.service_section .heading_container h2 {
  color: #000000;
  text-transform: uppercase;
  margin: 0;
}

.service_section .heading_container h2 span {
  color: #ffffff;
}

.slick-slider {
  margin: 0 20px;
}

.owl-prev,
.owl-next {
    background-color: #ffffff;
    border: none;
    font-size: 28px;
    color: #000000;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
}

.owl-prev {
  left: -60px;

}

.owl-next {
  right: -60px;
}

.owl-prev:hover, .owl-next:hover {
    background-color: #252525;
    color: #ffffff;
  }
  
  .owl-prev i, .owl-next i {
    font-size: 24px;
  }
    
.service_item {
  padding: 0 10px;
}

.service_section .box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s;
  padding: 10px;
  background: linear-gradient(to bottom, transparent 10%, #ffffff 10%);
  max-width: 400px; /* Set a maximum width */
  height: 100%;  
}

.service_section .img-box img {
  width: 100%;
  border-radius: 10px;
  max-height: 200px; /* Ensure the image does not exceed this height */
  object-fit: cover;  
}

.service_section .detail-box {
  padding: 15px;
  border-radius: 10px;
  transition: all 0.3s;
  flex-grow: 1;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;  
  overflow: hidden;  
}

.service_section .detail-box h5 {
  font-weight: bold;
  margin-bottom: 10px;  
}

.service_section .detail-box h6 {
  color: #19717d;
  font-weight: bold;
  margin-top: auto; 
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;  
  -webkit-box-orient: vertical;
}
