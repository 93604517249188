
.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
    background: #fdfbfb;
    width: 47px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%);
}

.carousel .slick-prev {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    
}

.carousel .slick-next {
    right: 0;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
}

.slick-prev::before,
.slick-next::before {
    display: none;
    
}

.custom-indicator {
    bottom: -65px;
}

.custom-indicator li {
    width: 50px;
    height: 50px;
    filter: grayscale(100%);
}

.custom-indicator li.slick-active {
    filter: grayscale(0%);
}

.multi__image:hover {
    transform: scale(1.1);
    transition: 500ms;
    cursor: pointer;
}

.nextarrow{
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    cursor: pointer;  
    z-index: 1; 
    margin-right: -10px;
    right: -55px;
}

.prevarrow{
    position: absolute;
    transform: translateY(-50%);
    top: 50%;   
    cursor: pointer;   
    left: -65px;
}

.heading{
    font-size: 3.6rem;
    font-family: "Poppins", sans-serif;
    display: flex;
    justify-content: center;
    padding-block: 3rem;
    text-align: center;
}

.carousel{
    margin-top: 55px;
    overflow-x: hidden;
    margin-inline: auto;
    max-width: 1600px;
  
}

.title{

    font-size: 1.4rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    position: relative;
    

}

.multi__image{ 

        width: 100%;
        height: 170px;
        object-fit: contain;

}

.slider{
    margin-inline: 45px;
}

.partnersblock{
    height: 220px;

}