.hero_area {
  position: relative;
}

.sub_page .hero_area {
  height: auto;
}
.feature_section {
  position: relative;
}

.feature_section .side_img {
  position: absolute;
  right: 8px;
  width: 175px;
  height: auto;
  bottom: 125px;
}

.feature_section .side_img img {
  width: 100%;
}

.feature_section .heading_container h2 {
  text-transform: none;
}

.feature_section .box {
  margin-top: 45px;
}

.feature_section .box .img-box {
  width: 100px;
}

.feature_section .box .img-box img {
  width: 100%;
}

.feature_section .box .detail-box {
  margin-top: 15px;
}

.feature_section .container .row .customise {
  margin: 8px;
}

.feature_section .box .detail-box h4 {
  font-weight: bold;
}

.feature_section .box .detail-box a {
  text-transform: uppercase;
  color: #19717d;
}

.feature_section .box .detail-box a:hover {
  color: #252525;
}
