* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.slick-slide > div {
  padding: 0 15px;
}

.hospital-header {
  text-align: center;
  margin-bottom: 20px;
}

.hospital-title {
  font-size: 2em;
  font-weight: bold;
  color: #19717d;
}

.hospital-subtitle {
  font-size: 1.2em;
  color: #666;
  margin-top: 10px;
}

.hospital-slider-prev img,
.hospital-slider-next img {
  width: 14px;
  height: 14px;
}

.hospital-slide {
  width: 50%;
  height: 180px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  max-width: 400px;
  margin-top: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(0, 0, 0, 0.05);
}

.hospital-slide-image {
  width: 50%;
  height: 60%;
  max-width: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
  margin: 20px auto;
}

.hospital-slide:hover {
  transform: scale(1.05);
}

.hospital-slide-image:hover {
  transform: scale(1.05);
}

.hospital-slider-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.hospital-slider-prev,
.hospital-slider-next {
  background-color: #19717d;
  color: white;
  font-size: 1em;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s;
 
}

.hospital-slider-prev:hover,
.hospital-slider-next:hover {
  background-color: black;
}

.hospital-slider {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .hospital-slide {
    width: 100%;
    height: 170px;
  }

  .hospital-slide-image {
    height: 90%;
    width: 70%;
    margin-top: 10px;
  }

  .slick-slide > div {
    padding: 0 5px;
  }

  .hospital-slider-controls {
    margin-top: 15px;
  }

  .hospital-slider-prev,
  .hospital-slider-next {
    font-size: 1em;
    display: block;
  }
}
