.faq_section .heading_container {
    margin-bottom: 35px;
  }
  
  .faq_section .heading_container h2 {
    text-transform: uppercase;
    margin: 0;
  }
  
  .faq_section .box {
    margin: 15px 45px;
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    padding: 25px;
  }
  
  .faq_section .box .faq_info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .faq_section .box .faq_info .faq_name h5 {
    font-weight: 600;
    color: #19717d;
  }
  
  .faq_section .box .faq_info .faq_name h6 {
    margin-bottom: 0;
    color: #bfc0c0;
    font-weight: normal;
    font-size: 15px;
  }
  
  .faq_section .box .faq_info i {
    font-size: 24px;
  }
  
  .faq_section .box p {
    margin-top: 15px;
  }
  
  .faq_section .carousel-control-prev,
  .faq_section .carousel-control-next {
    width: 45px;
    height: 125px;
    background-color: #19717d;
    opacity: 1;
    font-size: 28px;
    font-weight: bold;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  
  .faq_section .carousel-control-prev {
    left: 0;
  }
  
  .faq_section .carousel-control-next {
    right: 0;
  }