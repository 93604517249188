/* Base Button Styles */
.custom-btn {
    height: 50px;
    color: #fff;
    border-radius: 5px;
    padding: 2px 2px;
    /* Ensure no padding interferes */
    margin: 15px 0 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
        7px 7px 20px 0px rgba(0, 0, 0, .1),
        4px 4px 5px 0px rgba(0, 0, 0, .1);
    outline: none;
}

/* Specific Button Style */
.btn-11 {
    border: none;
    background: #252525;
    color: #fff;
    overflow: hidden;
    position: relative;
 }

.btn-11:hover {
    text-decoration: none;
    background-color: black;
    color: #fff;
    opacity: .7;
}

.btn-11:before {
    position: absolute;
    content: '';
    display: inline-block;
    top: -180px;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: #fff;
    animation: shiny-btn1 3s ease-in-out infinite;
}

.btn-11:active {
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, .3),
        -4px -4px 6px 0 rgba(116, 125, 136, .2),
        inset -4px -4px 6px 0 rgba(255, 255, 255, .2),
        inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
}

@keyframes shiny-btn1 {
    0% {
        transform: scale(0) rotate(45deg);
        opacity: 0;
    }

    80% {
        transform: scale(0) rotate(45deg);
        opacity: 0.5;
    }

    81% {
        transform: scale(4) rotate(45deg);
        opacity: 1;
    }

    100% {
        transform: scale(50) rotate(45deg);
        opacity: 0;
    }
}

.button-text {
     text-decoration: none;  
    display: flex;  
    justify-content: center;  
    align-items: center; 
    width: 100%;  
    height: 100%;  
    padding: 0;  
    margin: 0; 
}
