
@media screen and (max-width:1222px) {
    .title {
        top: -10px;
    }
}

@media screen and (max-width:950px) {
    .title {
        top: -20px;
    }
}


@media screen and (max-width:553px) {
    .heading {
        font-size: 3.3rem;
    }
}

@media screen and (max-width:700px) {
    .icon {
        height: 80px;
        width: 80px;
    }
}

@media screen and (max-width:600px) {
    .title {
        top: -35px;
        font-size: 1.3rem;
    }
}

@media screen and (max-width:426px) {
    .title {
        top: 4px;
     
    }
}

@media screen and (max-width:426px) {
    .multi__image{
        
       height: 150px;
      
    }
}