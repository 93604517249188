 .hero_area {
  position: relative;
}

.sub_page .hero_area {
  height: auto;
}

.sub_page .header_section {
  margin-top: 0;
}

.header_section .header_top {
  padding: 15px 0;
}

.header_section .header_top .contact_nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header_section .header_top .contact_nav a {
  margin-right: 25px;
  color: #555555;
  text-decoration: none;
}

.header_section .header_top .contact_nav a i {
  color: #19717d;
  font-size: 14px;
  margin-right: 5px;
}

.header_section .header_bottom {
  background-color: #19717d;
}

.header_section .header_bottom .container-fluid {
  padding-right: 25px;
  padding-left: 25px;
}

.navbar-brand {
  background-color: #ffffff;
  padding: 15px 35px;
}

.navbar-brand img {
  width: 100px;
}

.custom_nav-container {
  padding: 0;
}

.custom_nav-container .navbar-nav .nav-item .nav-link {
  padding: 3px 15px;
  margin: 10px 15px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
}

.custom_nav-container .navbar-nav .nav-item:hover .nav-link {
  color: #252525;
}

.custom_nav-container .nav_search-btn {
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
  color: #ffffff;
}

.custom_nav-container .nav_search-btn:hover {
  color: #252525;
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler {
  padding: 0;
  width: 37px;
  height: 42px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span {
  display: block;
  width: 35px;
  height: 4px;
  background-color: #ffffff;
  margin: 7px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span::before,
.custom_nav-container .navbar-toggler span::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  top: -10px;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span::after {
  top: 10px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span::before,
.custom_nav-container .navbar-toggler[aria-expanded="true"] span::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 0;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-1 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: 0;
  margin-bottom: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-2 {
  display: none;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-3 {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin: 0;
  margin-top: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-1,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-2,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-3 {
  -webkit-transform: none;
  transform: none;
}
 .custom_nav-container .navbar-toggler {
  outline: none;
  padding: 0;
  width: 37px;
  height: 42px;
  background-color: transparent;  
  border: none;  
  transition: background-color 0.3s;  
}

 
.custom_nav-container .navbar-toggler:hover {
  background-color: rgba(255, 255, 255, 0.2);  
}

.custom_nav-container .navbar-toggler span {
  display: block;
  width: 35px;
  height: 4px;
  background-color: #ffffff; 
  margin: 7px 0;
  border-radius: 5px;
  transition: background-color 0.3s;  
}

 .custom_nav-container .navbar-toggler:hover span {
  background-color: white;  
}

.quote_btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.quote_btn-container a {
  color: #ffffff;
  margin-right: 25px;
  text-transform: uppercase;
}

.quote_btn-container a span {
  margin-left: 5px;
}

.quote_btn-container a:hover {
  color: #252525;
}

                   