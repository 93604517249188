.jobs_section {
  background: white;
  opacity: 0.95;
}

.jobs_section .heading_container h2 {
  text-align: center;
  color: #000;

}

.jobs_section .detail-box h6 {
  font-size: 17px;
  font-weight: 600;
}

.jobs_section .heading_container h2 span {

  color: #19717D;
}



.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}

textarea {
  height: 100px;
}

.jobs_section .container .contain {
  background-color: #19717D;
  padding: 30px;
  border-radius: 10px;
}

.jobs_section .container .contain Button {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 20px;

}

Button:hover {
  background-color: #45a049;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 20px;
  height: 20px;
  border-radius: 60%;
  border-left-color: #ffffff;
  animation: spin 1s ease infinite;
  display: inline-block;
  margin-left: 10px;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 500px;
  width: 80%;
}

.popup-content h3 {
  margin-top: 0;
}

.popup-content button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.popup-content button:hover {
  background-color: #0056b3;
}
.NoJob{
  font-size: 50px;
  margin: 100px;
  text-align: center;
}
@media screen and (max-width: 990px) {
  .jobs_section .container .up {
    margin-top: -30px;
  }
  .jobs_section .container .heading_container .padding {
    position: relative;
    top: -20px;
  }
}

@media screen and (max-width: 769px) {
  .jobs_section .container .up {
    padding-top: 30px;

  }

  .jobs_section .container .up > div {
    padding-bottom: 20px;
    
  }
  .jobs_section .container .heading_container .padding {
    position: unset;
    
  }
}


@media screen and (max-width: 600px) {
  .container {
    width: 100%;
  }
}
